import React from 'react';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import PaymentForm from './components/PaymentForm';
import TransactionHistory from './components/TransactionHistory';
import './styles/App.css';

const App = () => (
  <div className="app">
    <Navbar />
    <Dashboard />
    <PaymentForm />
    <TransactionHistory />
  </div>
);

export default App;