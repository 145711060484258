import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/TransactionHistory.css';

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      const response = await axios.get('/.netlify/functions/getTransactions');
      setTransactions(response.data.transactions);
    };

    fetchTransactions();
  }, []);

  return (
    <div className="transaction-history">
      <h2>Transaction History</h2>
      <ul>
        {transactions.map((transaction, index) => (
          <li key={index}>{transaction}</li>
        ))}
      </ul>
    </div>
  );
};

export default TransactionHistory;