import React from 'react';
import '../styles/Dashboard.css';

const Dashboard = () => (
  <div className="dashboard">
    <h2>Dashboard</h2>
    <p>Welcome to your payment gateway dashboard. Here you can process payments and view transaction history.</p>
  </div>
);

export default Dashboard;