import React, { useState } from 'react';
import axios from 'axios';
import '../styles/PaymentForm.css';

const PaymentForm = () => {
  const [amount, setAmount] = useState('');

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/.netlify/functions/processPayment', { amount });
      alert(response.data.message);
    } catch (error) {
      console.error(error);
      alert('Payment failed');
    }
  };

  return (
    <form className="payment-form" onSubmit={handlePayment}>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Amount"
        required
      />
      <button type="submit">Pay</button>
    </form>
  );
};

export default PaymentForm;